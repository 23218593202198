<template>
  <div @click="workshopMonitorBack" class="workshopMonitorDetail"></div>
</template>

<script>
export default {
  name: "workshopMonitorDetail",
  data() {
    return {};
  },
  components: {},
  methods: {
    workshopMonitorBack() {
      this.$router.push({ name: "workshopMonitor" });
    },
  },
  mounted() {},
};
</script>

<style>
.workshopMonitorDetail {
  width: 100%;
  height: 100%;
  background: url("~@/assets/workshopMonitor/workshopMonitorDetail.png")
    no-repeat center;
  background-size: 100% 100%;
  position: relative;
}
</style>
